import React from 'react';
import { useDispatch } from 'react-redux';
import { trackEvent } from 'Tracking/Actions';
import FormSection from './FormSection';
import AboveTheFoldSection from './AboveTheFoldSection';
import SecondaryCTASection from './SecondaryCTASection';
import styles from './index.module.scss';
import PublicationSources from './PublicationSources';
import TestimonialSection from './TestimonialsSection';
import FreeResourcesSection from './FreeResourcesSection';
import { FORMS } from './Constants';

const Homepage = (): JSX.Element => {
  const dispatch = useDispatch();

  const fireTracking = (action: string) => {
    dispatch(trackEvent('Go to Page | Homepage', action));
  };

  return (
    <div className={styles['homepage-page-wrapper']}>
      <AboveTheFoldSection />
      <SecondaryCTASection />
      <div className={styles['testimonial-publication-container']}>
        <TestimonialSection />
        <PublicationSources />
      </div>
      <div className={styles['info-section']}>
        {FORMS.map((formItem) => (
          <FormSection
            key={formItem.description}
            description={formItem.description}
            fireTracking={fireTracking}
            links={formItem.links}
            type={formItem.type}
          />
        ))}
        <hr className={styles.border} />
        <div className={styles['browse-container']}>
          Can&apos;t find the document you&apos;re looking for? Try browsing
          our&nbsp;
          <a
            href="/legal-forms"
            onClick={() => fireTracking('Free Legal Forms')}
          >
            Legal Forms
          </a>
        </div>
      </div>
      <FreeResourcesSection fireTracking={fireTracking} />
    </div>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default Homepage;
